var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "mega-modal",
    {
      ref: "modal-2",
      staticClass: "modal-sm",
      attrs: { active: _vm.modal },
      on: {
        onClose: function($event) {
          _vm.modal = false
        }
      }
    },
    [
      _c(
        "div",
        {
          staticClass: "card-body p-4",
          on: {
            keypress: function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              )
                return null
              return _vm.submit.apply(null, arguments)
            }
          }
        },
        [
          _c("span", { staticClass: "form-label mr-3" }, [
            _vm._v(" " + _vm._s(_vm.$t("profit_perc")) + " ")
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "d-flex flex-column pt-2" },
            [
              _c("mega-range", {
                attrs: { min: 1, max: 50, step: 1 },
                model: {
                  value: _vm.percent,
                  callback: function($$v) {
                    _vm.percent = $$v
                  },
                  expression: "percent"
                }
              }),
              _vm._v(" "),
              _c("mega-number", {
                attrs: {
                  min: 1,
                  max: 50,
                  step: 1,
                  help: "Установить процент начислений"
                },
                model: {
                  value: _vm.percent,
                  callback: function($$v) {
                    _vm.percent = $$v
                  },
                  expression: "percent"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "navbar sm-down:flex-column-reverse md-up:justify-content-end"
            },
            [
              _c(
                "mega-button",
                {
                  staticClass: "bg-white sm-down:w-100 md-up:mr-2",
                  on: {
                    click: function($event) {
                      _vm.modal = false
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("close")))]
              ),
              _vm._v(" "),
              _c(
                "mega-button",
                {
                  staticClass: "btn-primary sm-down:w-100 sm-down:mb-3",
                  on: { click: _vm.submit }
                },
                [_vm._v(_vm._s(_vm.$t("confirm")))]
              )
            ],
            1
          )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }