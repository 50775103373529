<template>
  <mega-modal
    ref="modal-2"
    class="modal-sm"
    :active="modal"
    @onClose="modal = false"
  >
    <div class="card-body p-4" @keypress.enter="submit">
      <span class="form-label mr-3"> {{ $t("profit_perc") }} </span>
      <div class="d-flex flex-column pt-2">
        <mega-range :min="1" :max="50" :step="1" v-model="percent" />
        <mega-number
          :min="1"
          :max="50"
          :step="1"
          v-model="percent"
          :help="'Установить процент начислений'"
        />
      </div>
      <div class="navbar sm-down:flex-column-reverse md-up:justify-content-end">
        <mega-button
          class="bg-white sm-down:w-100 md-up:mr-2"
          @click="modal = false"
          >{{ $t("close") }}</mega-button
        >
        <mega-button
          class="btn-primary sm-down:w-100 sm-down:mb-3"
          @click="submit"
          >{{ $t("confirm") }}</mega-button
        >
      </div>
    </div>
  </mega-modal>
</template>

<script>
export default {
  data() {
    return {
      modal: false,
      percent: 40
    };
  },
  methods: {
    submit() {
      this.$api.v2
        .put("/finance/profit", {
          profit_percent: this.percent
        })
        .then(() => {
          this.modal = false;
          // window.location.reload();
        });
    },
    open() {
      this.modal = true;
    }
  }
};
</script>
